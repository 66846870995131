.result-wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.result-area {
    background-color: #f0f8ff;
    height: 250px;
    min-height: 200px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-text {
    font-size: 2.4rem;
    font-weight: 600;
}

.content-area {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex: 1 0 auto;
    padding: 20px;
    flex-direction: column;
}

.subtitle {
    font-size: 1.3rem;
    font-weight: 700;
    color: #3d3d3d;
}
.text-style1 {
    color : #898989;
    display: flex;
}

.text-style2 {
    color : #898989;
    display: flex;
    width : 360px;
    
    /* margin-bottom: 20px; */
}

.footer-btn {
    /* bottom: 0; */
    width: 100%;
}

.column-info {
    margin: 8px;
    display: flex;
    align-items: center;
    font-size: 0.87rem;
  }

.column-title {
    font-weight: 600;
}
  
.column-right {
    margin-left: auto;
  }