
@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 900;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-Black.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 800;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-ExtraBold.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 700;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-Bold.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 600;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-SemiBold.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 500;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-Medium.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 400;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-Regular.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 300;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-Light.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 200;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-ExtraLight.woff2) format('woff2');
}

@font-face {
	font-family: 'Pretendard GOV';
	font-weight: 100;
	font-display: swap;
	src: url(../assets/fonts/woff2/PretendardGOV-Thin.woff2) format('woff2');

}
* {
  font-family: 'Pretendard GOV', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
               'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* validation 색상 변경*/
.validation-error .MuiOutlinedInput-notchedOutline, .validation-error .MuiToggleButton-root{ 
  border: 1px solid #d32f2f !important;
}

/* MUI 필수 컴포넌트 asterisk 추가  */
.required label, label.required{
  color: #0978a6 !important;
}
.validation-error label, label.validation-error{
  color: #d32f2f !important;
}
.required label:after, .validation-error label:after, label.required:after, label.validation-error:after { 
  content:" *"; 
  font-size: 1.6em;
  position: relative;
  vertical-align: bottom;
  top: 2px;
}
.required fieldset legend, .validation-error fieldset legend{
  padding-right: 1.3em !important;
}

/* MUI 필수 컴포넌트 asterisk ver2 */
.validation-error2 .MuiOutlinedInput-notchedOutline, .validation-error2 .MuiToggleButton-root{ 
	border: 1px solid #d32f2f !important;
}

.required2-div{
	display:flex;
	align-items: center;
}
.required2-div label{
	display:flex;
	align-items:center;
	white-space: nowrap !important;
	margin-left: 0.6em !important;
	margin-right: 0.8em !important;
	font-size: 0.8em;
}
.required2 label, label.required2{
  	color: #0978a6 !important;
}
.validation-error2 label, label.validation-error2{
  	color: #d32f2f !important;
}
.required2 label:after, .validation-error2 label:after, label.required2:after, label.validation-error2:after { 
	content:" *"; 
	font-size: 1.4em;
	padding-left: 0.2em;
}

.required3-div{
	display:flex;
	align-items: flex-start;
    flex-direction: column;
}
.required3-div label{
	display:flex;
	align-items:center;
	white-space: nowrap !important;
	margin-left: 0.9em !important;
	margin-right: 0.8em !important;
	margin-bottom: -0.4em !important;
	font-size: 0.8em;
}
.required3 label, label.required3{
  	color: #0978a6 !important;
}
.validation-error3 label, label.validation-error3{
  	color: #d32f2f !important;
}
.required3 label:after, .validation-error3 label:after, label.required3:after, label.validation-error3:after { 
	content:" *"; 
	font-size: 1.4em;
	padding-left: 0.2em;
}