.subTitle {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.subTitle-btn {
  margin-left: auto;
}

.info-box {
  justify-content: center;
  flex-direction: column;
  display: flex;
  font-size: 14px;
  padding: 5px;
}
  
.itemTitle {
  font-size: 0.85em !important;
  color: #636363;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.item-center {
  display: flex;
  align-items: center;
  color: #616161;
}

.info-text {
  margin-top: 3px;
}

.info-text div {
  font-size: 0.82rem;
  color: #767171;
  display: flex;
  align-items: center;
}

.info-text span {
  color: #367dff;
  margin-left: 3px;
}





.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #4e73df;
    --bs-btn-border-color: #4e73df;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4262be;
    --bs-btn-hover-border-color: #3e5cb2;
    --bs-btn-focus-shadow-rgb: 105, 136, 228;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3e5cb2;
    --bs-btn-active-border-color: #3b56a7;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #4e73df;
    --bs-btn-disabled-border-color: #4e73df;
  }
  
  .mr5 {
    margin-right: 5px;
  }
  
  .fs07 {
    font-size: 0.7em;
  }

  table {
    font-size : 0.78em;
  }
