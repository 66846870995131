.login-page {
    background-color:#f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
  }

.login-form section {
    display: flex;
    align-items: center;
    color: #96a1b2;
    font-weight: 600;
  }
  
.login-form form {
    border-top: 2px solid #5ea4f3;
    background-color: #fff;
    max-width: 500px;
    margin: auto;
    padding: 40px;
    box-shadow: 0px 2px 10px rgba(0,0,0,.075);
  }

  .crm4u-logo {
    font-size: 3em;
    font-weight: 400;
    color: #4c88ea;
    text-shadow: 1px 5px 4px #00000029;
    margin-bottom: 10px;
  }