.header-area {
    position: relative;
    display: flex;
    width: 100%;
    padding: 20px 0 0 20px;
    justify-content: space-between;
}

.header-logo {
    font-size: 2rem;
    font-weight: 600;
    color: #4c88ea;
    cursor: pointer;
}

.header-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 20px;
}