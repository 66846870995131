.flexContainer {
    display: flex;
    height: 60px;
    /* justify-content: space-between; */
  }
  
.listItemContent5 {
    flex-basis: 5%;
    flex-shrink: 0;
    flex-grow: 0;
}
.listItemContent10 {
    flex-basis: 10%;
    flex-shrink: 0;
    flex-grow: 0;
}
.listItemContent80 {
    flex-basis: 80%;
    flex-shrink: 0;
    flex-grow: 0;
}

.container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .canvasContainer {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    width: 380px;
    height: 160px;
  }
  
  .canvasStyle {
    width: 100%;
    height: 100%;
  }
  
  .btnContainer{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .textContainer{
    padding-bottom: 5px;
    color: #717171;
  }

  .infoThText{
    /* text-align: right; */
  }

  .popupDaumDiv{
    position: absolute;
    left: 700px;
    top: 250px;
    /* z-index: 10001; */
    z-index:1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .popupDaum{
    background : rgba(0,0,0,0.25);
    position : fixed;
    z-index:10;
    left:0;
    top:0;
    height:100%;
    width:100%;
  }