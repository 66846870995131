.page-title {
    font-size: 2.0rem;
    font-weight: 600;
    margin-bottom: 15px;
}

.menu-title {
    font-size: 1.3rem;
    font-weight: 600;
    color: #585555;
    margin: 20px;
}
